import { useMutation } from '@apollo/client';
import { useState } from 'react';

import { ModalContainer } from '@oui/app-core/src/components/ModalContainer';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { Shadow, useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { GQLDateTime } from '@oui/lib/src/types/scalars';

import { useDailyCheckInContext } from '../../DailyCheckInContext/DailyCheckInContext';
import { DailyCheckInLevels, LevelValue } from '../DailyCheckInLevels/DailyCheckInLevels';
import { DailyCheckInResult } from '../DailyCheckInResult/DailyCheckInResult';

export const DailyCheckInModalMutation = graphql(`
  mutation DailyCheckInModal($input: MutationAddEatingCheckinInput!) {
    addEatingCheckin(input: $input) {
      ... on EatingCheckinPractice {
        practiceID
        eatingCheckin {
          urgeLevel
          mood
        }
        practiceValues {
          patient {
            ID
          }
        }
      }
    }
  }
`);

type Answers = {
  mood: LevelValue | null;
  urgeLevel: LevelValue | null;
};

export const DailyCheckInModal = ({
  trigger,
}: {
  trigger: 'EATING_LOG' | 'SESSION' | undefined;
}) => {
  const { theme } = useTheme();

  const [mutate] = useMutation(DailyCheckInModalMutation);
  const patientID = useCurrentPatientID()!;
  const [answers, setAnswers] = useState<Answers>({ mood: null, urgeLevel: null });
  const [promptTimestamp] = useState<GQLDateTime>(new Date().toISOString() as GQLDateTime);
  const { onHideDailyCheckIn } = useDailyCheckInContext();

  const updateAnswer = (key: keyof Answers, value: LevelValue) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
  };

  const onSubmit = async (completionButtonClicked: 'DONE' | 'SUGGESTION') => {
    if (!trigger) return;

    const now = new Date().toISOString() as GQLDateTime;

    await mutate({
      variables: {
        input: {
          eatingCheckin: {
            promptTimestamp,
            completionTimestamp: now,
            trigger,
            mood: answers.mood!,
            urgeLevel: answers.urgeLevel!,
            completionButtonClicked,
          },
          practiceValues: {
            patientID,
            ratings: [],
            date: formatGQLDate(),
          },
        },
      },
    });

    onHideDailyCheckIn();
  };

  return (
    <ModalContainer
      style={[
        {
          width: '90%',
          maxWidth: 400,
          backgroundColor: 'white',
          marginHorizontal: 10,
          borderRadius: 20,
          paddingVertical: 26,
          paddingHorizontal: 24,
        },
        Shadow.high,
      ]}
      visible={!!trigger}
      onRequestClose={onHideDailyCheckIn}
    >
      <View>
        <Heading
          level={2}
          text="Check-in"
          color={theme.color.gray100}
          style={{ marginBottom: 25 }}
        />
        {!answers.urgeLevel ? (
          <DailyCheckInLevels view="urge" onPress={(value) => updateAnswer('urgeLevel', value)} />
        ) : !answers.mood ? (
          <DailyCheckInLevels view="mood" onPress={(value) => updateAnswer('mood', value)} />
        ) : (
          <DailyCheckInResult answers={answers} onSubmit={onSubmit} />
        )}
      </View>
    </ModalContainer>
  );
};
