import { ComponentProps } from 'react';
import { Pressable } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Label, Lead, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

type UrgeLevelConfig = {
  value: LevelValue;
  label: string;
  color: string;
};

type MoodConfig = UrgeLevelConfig & {
  icon: IconType;
};

export type LevelValue = 1 | 2 | 3 | 4 | 5;

type IconType = ComponentProps<typeof Icon>['name'];

const LevelButton = ({
  value,
  label,
  icon,
  color,
  onPress,
}: {
  value?: number;
  label: string;
  icon?: IconType;
  color: string;
  onPress: () => void;
}) => {
  const { theme } = useTheme();
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        { opacity: pressed ? 0.5 : undefined },
        { flexDirection: 'row', alignItems: 'center', gap: 10 },
      ]}
    >
      <View
        style={{
          width: 80,
          height: 80,
          borderRadius: 80,
          backgroundColor: color,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon ? (
          <Icon size={24} name={icon} color="#fff" />
        ) : (
          <Lead text={value?.toString()} color="#fff" />
        )}
      </View>
      <Label color={theme.color.gray300} text={label} />
    </Pressable>
  );
};

const LevelSelector = ({
  type,
  onPress,
}: {
  type: 'mood' | 'urgeLevel';
  onPress: (value: LevelValue) => void;
}) => {
  const { theme } = useTheme();
  const config: MoodConfig[] | UrgeLevelConfig[] =
    type === 'mood'
      ? ([
          {
            value: 5,
            label: 'Great',
            icon: 'face-great',
            color: theme.color.accent100,
          },
          { value: 4, label: 'Good', icon: 'face-good', color: theme.color.accent200 },
          { value: 3, label: 'Okay', icon: 'face-okay', color: '#FFBD2E' },
          { value: 2, label: 'Bad', icon: 'face-bad', color: theme.color.primary200 },
          { value: 1, label: 'Awful', icon: 'face-terrible', color: '#D02F00' },
        ] as const)
      : ([
          { value: 1, label: 'No urge', color: theme.color.accentTwo100 },
          { value: 2, label: 'Low', color: theme.color.accentTwo100 },
          { value: 3, label: 'Medium', color: theme.color.accentTwo100 },
          { value: 4, label: 'High', color: theme.color.accentTwo100 },
          { value: 5, label: 'Extreme', color: theme.color.accentTwo100 },
        ] as const);

  return (
    <View style={{ gap: 10 }}>
      {config.map((item) => (
        <LevelButton
          key={item.value}
          value={type === 'urgeLevel' ? item.value : undefined}
          icon={type === 'mood' ? (item as MoodConfig).icon : undefined}
          label={item.label}
          color={item.color}
          onPress={() => onPress(item.value)}
        />
      ))}
    </View>
  );
};

export const DailyCheckInLevels = ({
  view,
  onPress,
}: {
  view: 'urge' | 'mood';
  onPress: (value: LevelValue) => void;
}) => {
  const { theme } = useTheme();

  if (view === 'urge') {
    return (
      <>
        <View style={{ gap: 7, alignItems: 'center' }}>
          <Lead text="Urge level" />
          <Text color={theme.color.gray300} text="Rate your urge to binge eat right now" />
        </View>
        <View style={{ marginTop: 50, alignItems: 'center' }}>
          <LevelSelector type="urgeLevel" onPress={onPress} />
        </View>
      </>
    );
  } else if (view === 'mood') {
    return (
      <>
        <View style={{ gap: 7, alignItems: 'center' }}>
          <Lead text="Mood" />
          <Text color={theme.color.gray300} text="Rate how you're feeling right now" />
        </View>
        <View style={{ marginTop: 50, alignItems: 'center' }}>
          <LevelSelector type="mood" onPress={onPress} />
        </View>
      </>
    );
  }
  return null;
};
