import { useMemo } from 'react';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

import BadPath from '../../../assets/BadPath.svg';
import GoodPath from '../../../assets/GoodPath.svg';
import { Widget, WidgetLinks } from '../../WidgetLinks/WidgetLinks';

interface CopyItem {
  message: string;
  suggestion: string;
}

interface Category {
  copy: CopyItem[];
  widgets: Widget[];
}

const options: Record<'highUrge' | 'lowMood' | 'bothOK', Category> = {
  highUrge: {
    copy: [
      {
        message: `Those urges are strong right now, but they won't last forever.`,
        suggestion: `Try to keep busy with a task you've been putting off or dive into a good book. Distraction can be a powerful tool.`,
      },
      {
        message: "It looks like you're experiencing strong urges.",
        suggestion:
          'Remember, these feelings are temporary. Engaging in a creative activity like painting or writing might help shift your focus.',
      },
      {
        message: 'Your urges seem quite high at the moment.',
        suggestion:
          'How about going for a run or doing some yoga? Physical activity can help reduce those intense feelings and clear your mind.',
      },
      {
        message: 'High urges can be overwhelming, but they will pass.',
        suggestion:
          'Consider calling a friend, watching a favorite show, or cooking a meal. Keeping your mind engaged helps manage these moments.',
      },
      {
        message: 'Feeling strong urges right now?',
        suggestion: `This is a good time to test out a new hobby or revisit an old one. Activities that absorb your attention can lessen the intensity of what you're feeling.`,
      },
    ],
    widgets: [
      'DISTRACTING_ACTIVITY',
      'TALK_TO_SUPPORTERS',
      'PLAN_AN_ACTIVITY',
      'URGE_SURFING',
      'BREATHING_EXERCICE',
      'VALUES_KIT',
      'REVIEW_MOOD_PLAN',
      'MOOD_TOLERANCE',
      'RELAPSE_PLAN',
    ],
  },
  lowMood: {
    copy: [
      {
        message: "It seems like you're not feeling your best today.",
        suggestion:
          'Try stepping outside for a walk or dancing to your favorite song. Small steps can make a big difference!',
      },
      {
        message: 'Feeling low can be tough.',
        suggestion:
          'How about doing something you enjoy? Maybe reading, drawing, or playing music could help brighten your day.',
      },
      {
        message: 'Your mood seems down.',
        suggestion:
          'Remember, activity can be a great mood lifter. Consider doing some light exercise or a quick stretch to feel better.',
      },
      {
        message: 'Low mood days happen.',
        suggestion:
          'Why not try to lift it by doing something pleasant? Even a short burst of activity, like a puzzle or a craft, can help.',
      },
      {
        message: `It's okay to feel down.`,
        suggestion:
          'Why not try to shake it off with a brisk walk or by spending time in nature? Getting moving can often lift your spirits.',
      },
    ],
    widgets: [
      'REVIEW_MOOD_PLAN',
      'MOOD_TOLERANCE',
      'DISTRACTING_ACTIVITY',
      'PLAN_AN_ACTIVITY',
      'BREATHING_EXERCICE',
      'TALK_TO_SUPPORTERS',
      'VALUES_KIT',
      'URGE_SURFING',
      'RELAPSE_PLAN',
    ],
  },
  bothOK: {
    copy: [
      {
        message: 'Nice work today!',
        suggestion:
          'Your mood and eating urges are right where they should be. Keep this good balance going.',
      },
      {
        message: "You're on a good path with your mood and eating urges.",
        suggestion: "It's nice to see your healthy scores. Let's continue this steady progress.",
      },
      {
        message: "Your scores today show you're managing well.",
        suggestion:
          'Your mood is stable and your eating urges are under control. Good job maintaining this.',
      },
      {
        message: `You're keeping your feelings and eating urges in a good range.`,
        suggestion: `That's important for your well-being. Let's keep this pace.`,
      },
      {
        message: 'Your mood and urges are in a healthy range today.',
        suggestion: 'This is a good sign of your self-care. Keep up this steady approach.',
      },
      {
        message: 'Your numbers today are just right.',
        suggestion: `You're managing your mood and eating urges well. Keep this balanced effort going.`,
      },
    ],
    widgets: [
      'VALUES_KIT',
      'RELAPSE_PLAN',
      'TALK_TO_SUPPORTERS',
      'PLAN_AN_ACTIVITY',
      'BREATHING_EXERCICE',
      'DISTRACTING_ACTIVITY',
      'URGE_SURFING',
      'REVIEW_MOOD_PLAN',
      'MOOD_TOLERANCE',
    ],
  },
};

export const DailyCheckInResult = ({
  onSubmit,
  answers,
}: {
  onSubmit: (completionButtonClicked: 'DONE' | 'SUGGESTION') => void;
  answers: {
    mood: 1 | 2 | 3 | 4 | 5 | null;
    urgeLevel: 1 | 2 | 3 | 4 | 5 | null;
  };
}) => {
  const { theme } = useTheme();

  const result = useMemo(() => {
    function getRandomCopy(category: 'highUrge' | 'lowMood' | 'bothOK'): CopyItem {
      const categoryData = options[category];
      const randomIndex = Math.floor(Math.random() * categoryData.copy.length);
      return categoryData.copy[randomIndex];
    }

    function getCategoryWidgets(category: 'highUrge' | 'lowMood' | 'bothOK'): Widget[] {
      const categoryData = options[category];
      return categoryData.widgets;
    }

    if (!answers.mood || !answers.urgeLevel) {
      return;
    }

    let category: 'highUrge' | 'lowMood' | 'bothOK';
    if (answers.urgeLevel >= 4) {
      category = 'highUrge';
    } else if (answers.mood <= 2) {
      category = 'lowMood';
    } else {
      category = 'bothOK';
    }

    const { message, suggestion } = getRandomCopy(category);
    const widgets = getCategoryWidgets(category);

    return {
      widgetsTitle:
        category === 'bothOK'
          ? 'Helpful exercises'
          : category === 'lowMood'
            ? 'More ways to increase your mood'
            : 'More ways to reduce your urge',
      image: category === 'bothOK' ? <GoodPath aria-label="" /> : <BadPath aria-label="" />,
      copy: [message, suggestion],
      widgets,
    };
  }, [answers]);

  return !result ? (
    <ActivityIndicator />
  ) : (
    <View style={{ marginBottom: -10 }}>
      <View style={{ gap: 25, alignItems: 'center' }}>
        {result.image}
        {result.copy.map((copy) => (
          <Label key={copy} color={theme.color.gray300} text={copy} textAlign="center" />
        ))}
      </View>

      <WidgetLinks
        label={result.widgetsTitle}
        widgets={result.widgets}
        onPress={() => onSubmit('SUGGESTION')}
      />

      <Button
        text="Done"
        variant="solid"
        style={{ alignSelf: 'center', marginTop: 25 }}
        onPress={() => onSubmit('DONE')}
      />
    </View>
  );
};
