import { useNavigation } from '@react-navigation/native';

import { Learn as LearnScreen } from '@oui/app-core/src/screens/Learn';
import { useTheme } from '@oui/app-core/src/styles';

import { TabScreenProps } from '@src/types/navigation';

export function Learn(_props: {}) {
  const navigation = useNavigation<TabScreenProps<'Learn'>['navigation']>();
  const { theme } = useTheme();

  return (
    <LearnScreen
      onSessionCardPress={(session, sessionNum) => {
        const isComplete = session.status === 'COMPLETED';

        navigation.navigate('Conversation', {
          num: sessionNum.toString(),
          title: session.session.name,
          ID: session.session.contentType || 'TEST::convo',
          completed: isComplete ? ('true' as const) : ('false' as const),
        });
      }}
      progressBackgroundColor={theme.color.accent100}
      paddingBottom={150}
    />
  );
}
