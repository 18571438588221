import type { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import type { CompositeScreenProps } from '@react-navigation/native';
import type { StackScreenProps } from '@react-navigation/stack';

import { ACTIVITY_DIARY_DEEPLINK_CONFIG, ActivityDiaryStackParamList } from '@oui/activity-diary';
import {
  CORE_DEEPLINK_CONFIG,
  CoreRootStackParamList,
  DeeplinkConfigShape,
  NavigatorScreenParams,
} from '@oui/app-core/src/types/navigation';
import {
  BINGE_EATING_MY_PLAN_DEEPLINK_CONFIG,
  BingeEatingMyPlanStackParamList,
} from '@oui/binge-eating-my-plan';
import { EATING_LOG_DEEPLINK_CONFIG, EatingLogStackParamList } from '@oui/eating-log';
import { HOPE_KIT_DEEPLINK_CONFIG, HopeKitStackParamList } from '@oui/hope-kit';
import { RELAX_DIARY_DEEPLINK_CONFIG, RelaxDiaryStackParamList } from '@oui/relax-diary';
import { SLEEP_DIARY_DEEPLINK_CONFIG, SleepDiaryStackParamList } from '@oui/sleep-diary';

export type RootStackParamList = {
  home: NavigatorScreenParams<HomeTabParamList>;
  Welcome: undefined;
  Confidentiality: undefined;
  CreateTestUser: undefined;
  GetStarted: undefined;
  MyProgress: undefined;
} & CoreRootStackParamList &
  EatingLogStackParamList &
  ActivityDiaryStackParamList &
  HopeKitStackParamList &
  BingeEatingMyPlanStackParamList &
  RelaxDiaryStackParamList &
  SleepDiaryStackParamList;

export type RootStackScreenProps<T extends keyof RootStackParamList> = StackScreenProps<
  RootStackParamList,
  T
>;

export type HomeTabParamList = {
  Home: undefined;
  Learn: undefined;
  Log: undefined;
  Practice: undefined;
  UserProfile: undefined;
};

export type TabScreenProps<T extends keyof HomeTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<HomeTabParamList, T>,
  RootStackScreenProps<keyof RootStackParamList>
>;

const homeConfig = {
  Home: 'home',
  Learn: 'learn',
  Log: 'log',
  Practice: 'practice',
  UserProfile: 'profile',
} as const satisfies DeeplinkConfigShape<keyof HomeTabParamList>;

export const DEEPLINK_CONFIG = {
  home: { screens: homeConfig },
  Welcome: 'welcome',
  Confidentiality: 'confidentiality',
  CreateTestUser: 'create-test-user',
  GetStarted: 'get-started',
  MyProgress: 'progress',
  ...ACTIVITY_DIARY_DEEPLINK_CONFIG,
  ...BINGE_EATING_MY_PLAN_DEEPLINK_CONFIG,
  ...CORE_DEEPLINK_CONFIG,
  ...EATING_LOG_DEEPLINK_CONFIG,
  ...HOPE_KIT_DEEPLINK_CONFIG,
  ...RELAX_DIARY_DEEPLINK_CONFIG,
  ...SLEEP_DIARY_DEEPLINK_CONFIG,
} as const satisfies DeeplinkConfigShape<keyof RootStackParamList>;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends RootStackParamList {}
  }
}
