import { useNavigation } from '@react-navigation/native';
import { Image } from 'react-native';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Button } from '@oui/app-core/src/components/Button';
import { Heading, Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { RootStackScreenProps } from '@src/types/navigation';

export function Confidentiality(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<RootStackScreenProps<'Confidentiality'>['navigation']>();

  const onSubmit = () => {
    navigation.navigate('GetStarted');
  };

  return (
    <AuthScreenContainer testID="Confidentiality" heading={null}>
      <Image
        source={require('../assets/Confidentiality.png')}
        style={{ width: 66, height: 85, alignSelf: 'center', marginLeft: -10 }}
      />

      <Heading
        text={$t({ id: 'Confidentiality_title', defaultMessage: 'Confidential' })}
        level={1}
        textAlign="center"
      />

      <View
        style={{
          gap: 16,
          marginTop: 16,
          marginBottom: 28,
          paddingHorizontal: 20,
        }}
      >
        <Label
          textAlign="center"
          text={$t({
            id: 'Confidentiality_messageConfidential',
            defaultMessage: 'Everything shared in this app is confidential.',
          })}
        />

        <Label
          textAlign="center"
          text={$t({
            id: 'Confidentiality_messagePermissions',
            defaultMessage:
              'We will not share anything with others unless you give specific permission.',
          })}
        />
      </View>

      <Button
        alignSelf="center"
        onPress={onSubmit}
        text={$t({ id: 'Confidentiality_submitButton', defaultMessage: 'Got it' })}
        variant="solid"
      />
    </AuthScreenContainer>
  );
}
