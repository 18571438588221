import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { LessonsLearned } from '@oui/app-core/src/components/LessonsLearned/LessonsLearned';
import { ProfileHeader } from '@oui/app-core/src/components/ProfileHeader/ProfileHeader';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';

import { MyProgress } from '@src/components/MyProgress/MyProgress';

export function UserProfile() {
  const safeArea = useSafeAreaInsets();
  return (
    <ScrollView
      bottomOverflowColor="white"
      style={{ flex: 1 }}
      contentContainerStyle={{
        paddingTop: safeArea.top + 20,
        paddingBottom: safeArea.bottom + 150,
      }}
      testID="Profile_scrollView"
    >
      <ProfileHeader />
      <LessonsLearned />
      <MyProgress />
    </ScrollView>
  );
}
