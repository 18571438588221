import { PartialTheme } from './styles';

export const changeBiteTheme: PartialTheme = {
  color: {
    primary100: '#FF5929',
    primary200: '#F68827',
    primary300: '#FFD4AE',
    dark: '#2C4971',
    accent100: '#2A9205',
    accent200: '#61C43D',
    accent300: '#C7F0B7',
    accentTwo100: '#5A79E8',
    accentTwo200: '#869FF8',
    accentTwo300: '#BFCDFF',
    accentThree100: '#FEC344',
    accentThree200: '#FFDD9A',
    accentThree300: '#FFEFCE',
  },
};
