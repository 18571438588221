import { Image } from 'react-native';

export function OnboardingGraphic() {
  return (
    <Image
      source={require('@src/assets/SignUp.png')}
      style={{
        position: 'absolute',
        width: '100%',
        height: undefined,
        bottom: 0,
        aspectRatio: 375 / 135,
      }}
    />
  );
}
