import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';

import { MyProgress as MyProgressComponent } from '@src/components/MyProgress/MyProgress';

export function MyProgress(_props: {}) {
  return (
    <RoundedSection applyHeaderOptions title="My progress" color="white">
      <MyProgressComponent inRoundedSection />
    </RoundedSection>
  );
}

registerChatArtifactPreviewComponent('MyProgress', () => MyProgress);
