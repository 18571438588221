import { ApolloClient } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/core';
import { AsyncStorageWrapper, CachePersistor, MMKVWrapper } from 'apollo3-cache-persist';
import { ComponentProps, forwardRef, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { AppContainer } from '@oui/app-core/src/components/AppContainer';
import { FLAGS, IS_PRODUCTION } from '@oui/app-core/src/constants';
import { createApolloCache, createApolloClient } from '@oui/app-core/src/lib/apolloClient';
import { getMmkv, initMmkv } from '@oui/app-core/src/lib/mmkv';
import { getConfigString, initLastConfig } from '@oui/app-core/src/lib/remoteConfig';
import { ThemeProvider } from '@oui/app-core/src/styles';

import FullLogo from '@src/assets/Full_logo.svg';
import { OnboardingGraphic } from '@src/components/OnboardingGraphic';
import { RootNavigator } from '@src/components/RootNavigator';
import { WidgetProviders } from '@src/components/WidgetProviders/WidgetProviders';
import { changeBiteTheme } from '@src/theme';
import { DEEPLINK_CONFIG } from '@src/types/navigation';

import { DailyCheckInProvider } from './components/DailyCheckInContext/DailyCheckInContext';

const getMessages: ComponentProps<typeof AppContainer>['getMessages'] = ({ lang }) => {
  switch (lang) {
    case 'en': {
      return require('@src/messages/compiled/en.json');
    }
    default:
      return {};
  }
};

type Props = {};
export default forwardRef<AppContainer, Props>(function App({}: Props, ref) {
  const [apollo, setApollo] = useState<ApolloClient<unknown>>();

  useEffect(() => {
    // This will only run in development when fast-refresh kicks in
    let cleanupRef = { current: () => {} };

    async function initApollo() {
      const cache = createApolloCache();

      // TODO move to mountApp?
      await initMmkv();

      try {
        const options =
          Platform.OS === 'web'
            ? IS_PRODUCTION
              ? undefined
              : {
                  cache,
                  trigger: 'write' as const,
                  storage: new AsyncStorageWrapper(AsyncStorage),
                }
            : {
                cache,
                trigger: 'background' as const,
                storage: new MMKVWrapper(getMmkv('apollo')),
              };

        if (options) {
          const persistor = new CachePersistor(options);
          await persistor.restore();
          cleanupRef.current = () => persistor.remove();
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      await initLastConfig();
      const client = createApolloClient(getConfigString('apiUrl'), {
        subscriptionUri: getConfigString('subscriptionUri'),
        cache,
        connectToDevTools: true,
      });
      setApollo(client);
      const start = Date.now();
      const removedKeys = cache.gc();
      const duration = Date.now() - start;
      Sentry.addBreadcrumb({
        category: 'apollo',
        message: 'cache.gc',
        data: { removedKeys, duration },
      });
    }
    initApollo().catch(Sentry.captureException);

    return () => cleanupRef.current();
  }, []);

  return apollo ? (
    <ThemeProvider theme={changeBiteTheme}>
      <AppContainer
        Logo={FullLogo}
        onboardingGraphic={<OnboardingGraphic />}
        flags={FLAGS}
        app={() => (
          <DailyCheckInProvider>
            <WidgetProviders>
              <RootNavigator />
            </WidgetProviders>
          </DailyCheckInProvider>
        )}
        apollo={apollo}
        ref={ref}
        initialPath={({
          ouiUser,
        }): Parameters<GeneratedClientRoutes.GetDeeplinkPath['changebite']>[0] => {
          if (!ouiUser) return '/welcome';
          return '/home';
        }}
        deeplinkConfig={{
          screens: DEEPLINK_CONFIG,
        }}
        getMessages={getMessages}
      />
    </ThemeProvider>
  ) : null;
});
