import RNLottieView, { AnimationObject } from 'lottie-react-native';
import { ComponentProps, useState } from 'react';
import { ViewProps } from 'react-native';

// https://github.com/chromaui/chromatic-cli/blob/c971fd0c7746bf396e2571f9ec0ad4d81e145499/isChromatic.js#L1
function isChromatic() {
  const windowToCheck = typeof window !== 'undefined' && window;
  return !!(
    windowToCheck &&
    (windowToCheck.navigator?.userAgent?.match(/Chromatic/) ||
      windowToCheck.location?.href?.match(/chromatic=true/))
  );
}

/**
 * Renders a lottie view that takes up full width by default while maintaining
 * aspect ratio of the source asset
 */
export function LottieView(props: ComponentProps<typeof RNLottieView>) {
  const [style, setStyle] = useState<ViewProps['style']>({
    width: '100%',
    height: undefined,
  });

  return (
    <RNLottieView
      {...props}
      loop={global.e2e || isChromatic() ? false : props.loop}
      style={[{ width: '100%' }, style, props.style]}
      onLayout={(e) => {
        const source = props.source as AnimationObject;
        if ('w' in source && 'h' in source) {
          const ratio = source.w / source.h;
          const width = e.nativeEvent.layout.width;
          setStyle({ width, height: width / ratio });
        }
      }}
    />
  );
}
