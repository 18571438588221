import { useNavigation } from '@react-navigation/native';
import { ComponentProps } from 'react';
import { Pressable } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useProgress } from '@oui/app-core/src/hooks/useCurrentUser';
import { useTheme } from '@oui/app-core/src/styles';
import { ContentType } from '@oui/lib/src/types';

export type Widget =
  | 'VALUES_KIT'
  | 'RELAPSE_PLAN'
  | 'TALK_TO_SUPPORTERS'
  | 'PLAN_AN_ACTIVITY'
  | 'BREATHING_EXERCICE'
  | 'DISTRACTING_ACTIVITY'
  | 'URGE_SURFING'
  | 'REVIEW_MOOD_PLAN'
  | 'MOOD_TOLERANCE';

export const WidgetLinks = ({
  widgets,
  onPress,
  label,
}: {
  widgets: Widget[];
  onPress?: () => void;
  label: string;
}) => {
  const { theme } = useTheme();
  const { navigate } = useNavigation();

  const { data } = useProgress();

  const progress =
    data?.patientByPatientID?.progress
      .filter((progress) => progress.completed)
      .map((progress) => progress.content) ?? [];

  const widgetList: Record<
    Widget,
    {
      id: string;
      routeName: string;
      label: string;
      bgColor: string;
      icon: ComponentProps<typeof Icon>['name'];
      iconColor: string;
      contentType: ContentType;
    }
  > = {
    VALUES_KIT: {
      id: 'VALUES_KIT',
      routeName: 'HopeKit',
      label: 'Review your values Kit',
      bgColor: theme.color.primary300,
      iconColor: '#B45C0F',
      icon: 'kit',
      contentType: ContentType.HOPE_KIT,
    },
    RELAPSE_PLAN: {
      id: 'RELAPSE_PLAN',
      routeName: 'BingeEatingMyPlan',
      label: 'Go over your relapse plan',
      bgColor: '#BFCDFF99',
      iconColor: theme.color.accentTwo100,
      icon: 'sessions',
      contentType: ContentType.RELAPSE_PREVENTION,
    },
    TALK_TO_SUPPORTERS: {
      id: 'TALK_TO_SUPPORTERS',
      routeName: 'BingeEatingMyPlan',
      label: 'Talk to your supporters',
      bgColor: '#BFCDFF99',
      iconColor: theme.color.accentTwo100,
      icon: 'chat-double',
      contentType: ContentType.SOCIAL_SUPPORT,
    },
    PLAN_AN_ACTIVITY: {
      id: 'PLAN_AN_ACTIVITY',
      routeName: 'ActivityDiary',
      label: 'Plan an activity',
      bgColor: theme.color.accent300,
      iconColor: theme.color.accent100,
      icon: 'diary',
      contentType: ContentType.ACTIVITY_PLANNING,
    },
    BREATHING_EXERCICE: {
      id: 'BREATHING_EXERCICE',
      routeName: 'Relaxation',

      label: 'Do a breathing exercise',
      bgColor: theme.color.accentThree300,
      iconColor: '#705214',
      icon: 'breathe',
      contentType: ContentType.RELAXATION,
    },
    DISTRACTING_ACTIVITY: {
      id: 'DISTRACTING_ACTIVITY',
      routeName: 'BingeEatingMyPlan',
      label: 'Do a distracting activity',
      bgColor: '#BFCDFF99',
      iconColor: theme.color.accentTwo100,
      icon: 'choice',
      contentType: ContentType.REMOVING_BARRIERS,
    },
    URGE_SURFING: {
      id: 'URGE_SURFING',
      routeName: 'Relaxation',
      label: 'Use urge surfing',
      bgColor: theme.color.accentThree300,
      iconColor: '#705214',
      icon: 'surf',
      contentType: ContentType.URGE_SURFING,
    },
    REVIEW_MOOD_PLAN: {
      id: 'REVIEW_MOOD_PLAN',
      routeName: 'BingeEatingMyPlan',
      label: 'Review your mood plan',
      bgColor: '#BFCDFF99',
      iconColor: theme.color.accentTwo100,
      icon: 'bulleted-list',
      contentType: ContentType.MOOD_MODULATION,
    },
    MOOD_TOLERANCE: {
      id: 'MOOD_TOLERANCE',
      routeName: 'Relaxation',
      label: 'Try mood tolerance',
      bgColor: theme.color.accentThree300,
      iconColor: '#705214',
      icon: 'mood',
      contentType: ContentType.MOOD_TOLERANCE,
    },
  };

  const filteredWidgets = widgets
    .filter((widget) => progress.includes(widgetList[widget].contentType))
    .slice(0, 3);

  if (!filteredWidgets.length) return null;

  return (
    <View
      style={{
        marginHorizontal: -24,
        paddingHorizontal: 24,
        paddingTop: 25,
        marginTop: 25,
        borderTopColor: theme.color.gray600,
        borderTopWidth: 1,
        gap: 15,
      }}
    >
      <Label small color={theme.color.gray200} text={label} textAlign="center" />
      {filteredWidgets.map((widget, index) => {
        const { bgColor, icon, iconColor, label: widgetLabel, routeName } = widgetList[widget];

        return (
          <Pressable
            key={widget}
            testID={`Widget_${index}`}
            onPress={() => {
              onPress?.();
              // eslint-disable-next-line
              navigate(routeName as any);
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              backgroundColor: bgColor,
              paddingHorizontal: 15,
              paddingVertical: 12,
              borderRadius: 10,
              flexDirection: 'row',
              alignItems: 'center',
              gap: 12,
            })}
          >
            <Icon name={icon} size={20} color={iconColor} />
            <Label text={widgetLabel} />
          </Pressable>
        );
      })}
    </View>
  );
};
